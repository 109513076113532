class DataStore {

    constructor ({ data }) {
        this._data = data;
    }

    read (key) {
        return this._data[key];
    }

    // noinspection JSUnusedGlobalSymbols
    write () {
        throw new Error('Not Supported');
    }

}

module.exports = { DataStore };
