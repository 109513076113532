import React from "react"
import classNames from "classnames"
import "./title.scss"

export const Title = props => {
  const classnames = classNames("e-title", {
    // size
    "e-title-hero": props.hero,
    "e-title-large": props.large,
    "e-title-extra-large": props.extralarge,
    "e-title-regular": props.regular,
    "e-title__collapse": props.collapse,
    "e-title__form": props.form,
    "e-title__date": props.date,
    "e-title__small": props.small,
    "e-title__infobox": props.infobox,
    "e-title__steps": props.steps,
    "e-title__card": props.isCard,
    "e-title__icon": props.icon,
    "e-title__grand": props.grand,
    "e-title-large__section": props.isSection,
    "e-title__main": props.isMain
  });

  var img = null;
  if (props.icon) {
    img = <img src={props.icon}/>
  }

  return (
    props.hero ?
      <h1 className={classnames} data-qa={props.dataQa}>
        {img}{props.content}
      </h1>
    :
      <div className={classnames} data-qa={props.dataQa}>
        {img}{props.content}
      </div>

  )
}
