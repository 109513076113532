import React from "react"
import PropTypes from "prop-types"
import Footer from "./Footer/footer"
import { Location } from "@reach/router"
import "./style.scss"
import "../scss/global.scss"
import headerPosition from "./headerPosition"
import Header from "./Header/header"
import Disclaimer from "./Disclaimer/disclaimer";

headerPosition()

const Layout = ({ children, bgColor, heahTitle, headerTitle, hideFooterLogo }) => {
  const stickyName = `u-sticky-footer ${bgColor}`;
 
  return (
    <Location>
      {({ location }) => {
        let pathname = location.pathname === "/" ? "home" : location.pathname
        let showDisclaimer = (pathname !== "/instructions-new-passport/" 
                              && pathname !== "/instructions-passport-renewal/" 
                              && pathname !== "/thank-you/"
                              && pathname !== "/photo-requirements/"
                              && pathname !== "/passport-fees/"
                              && pathname !== "/proof-of-us-citizenship/"
                              && pathname !== "/proof-of-identity/"
                              )
        const disclaimerComponent = showDisclaimer ? <Disclaimer/> : null ;

        return (
          <div className={`${stickyName} ${pathname}`}>
            {disclaimerComponent}
            <Header headerTitle={headerTitle ? headerTitle : undefined} className={`sticky`} heahTitle={heahTitle} />
            <main>{children}</main>
            <Footer hideFooterLogo={hideFooterLogo}/>
          </div>
        )
      }}
    </Location>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
