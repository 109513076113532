import React from "react"
import "./photoRequirements.scss"
import Man from "../../../images/instrucctions/Man-Passport.jpg"
import Woman from "../../../images/instrucctions/woman-passport.jpg"
import Baby from "../../../images/instrucctions/baby-passport.jpg"
import WomanMuslim from "../../../images/instrucctions/Woman-muslim-passport.jpg"
import Mockup from "../../../images/instrucctions/illust-photo.svg"



const PhotoRequirements = () => {

  return (
    <span className="m-photoRequirements">
        <div className="m-photoRequirements--item">
            <img src={Man} />
        </div>
        <div className="m-photoRequirements--item">
            <img src={Woman} />
        </div>
        <div className="m-photoRequirements--item">
            <img src={WomanMuslim} />
        </div>
        <div className="m-photoRequirements--item">
            <img src={Baby} />
        </div>
        <div className="m-photoRequirements--item">
            <img src={Mockup} />
        </div>
    </span>
  )
}


export default PhotoRequirements
