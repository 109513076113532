import CookieConsent from "react-cookie-consent"
import React from "react"
import "./cookiePolicy.scss"


const CookiePolicy = () => {
  return (
    <CookieConsent location="bottom"
                   buttonText="Accept"
                   acceptOnScrollPercentage={10}
                   cookieName="cookiePolicy"
                   containerClasses="cookieNotice"
                   style={{ background: "#3C4043" }}
                   acceptOnScroll={true}
                   buttonStyle={{ background: "#576194", color: "white", fontSize: "16px" }}
                   expires={150}>
  <span style={{ fontSize: "14px", fontFamily: "Archivo" }}>
This website uses its own or third-party cookies to improve your experience and our services, by analyzing your navigation of our website. By clicking Accept or continue browsing, we consider you to have accepted said cookies. To get more info or how to change your privacy settings, see our <a
    style={{ color: "white" }} href="/cookie-policy">Cookies Policy</a>.
</span>
    </CookieConsent>
  )
}

export default CookiePolicy
