import React from "react"
import FooterNavigation from "./FooterNavigation/footerNavigation"
import Wrapper from "../Wrapper/wrapper.js"
import "./footer.scss"
import { graphql, StaticQuery } from "gatsby"
import RichText from "../../Modules/RichText/richText";
import { Location } from "@reach/router";
import PaymentDisclaimer from '../Footer/paymentDisclaimer'

const Footer = (props) => {
  return (
    <footer>
      <div className="m-footer-container">
        <FooterNavigation hideFooterLogo={props.hideFooterLogo}/>
      </div>
      <div className="m-footer">
        <div className="m-footer--header">
        </div>
        <Location>
          {({ location }) => {
            return (
              <div className="m-footer--body">
                <Wrapper>

                  <StaticQuery query={query}
                              render={data => (
                                <RichText content={data.contentfulFooterNavigation.disclaimer.json}/>
                              )}/>
                </Wrapper>
              </div>
            )
          }}
        </Location>
      </div>
    </footer>
  )
};

export default Footer

const query = graphql`
    {
        contentfulFooterNavigation {
            navigationItems {
                ... on ContentfulLegal {
                    internal {
                        type
                    }
                    id
                    title
                    slug
                }
            }
            disclaimer{
                json
            }
        }
        site{
            siteMetadata{
                company_name
                company_vat
                web_name
                web_mail
            }
        }
    }
`
