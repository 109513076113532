import React from "react"
import {graphql, Link, StaticQuery} from "gatsby"
import "./navigation.scss"
import comodoSecure from "../../../../images/logos/comodo-secure.png"
import ssl from "../../../../images/logos/ssl.png"
import { AST_PropAccess } from "terser"

const FooterNavigation = (props) => {
  return(
  
  <StaticQuery
    query={query}
    render={data => {
      return(
      <nav className="m-footer-navigation">
        {!props.hideFooterLogo &&
          <div className="logo-container">
            <img src={ssl}/>
            <img src={comodoSecure}/>
          </div>
        }
        <ul className="m-footer-navigation-container">
          {!props.hideFooterLogo &&
            data.contentfulFooterNavigation.navigationItems.map((page, index) => {
              return (
                <li key={index} className="m-footer-navigation-item">
                  <a
                    className="m-footer-navigation-link"
                    href={`/${page.slug}/`}>
                    {page.title}
                  </a>
                </li>
              )
            })
          }
        </ul>
      </nav>
    )}}
  />
)

        }
export default FooterNavigation

const query = graphql`
    {
        contentfulFooterNavigation {
            navigationItems {
                ... on ContentfulLegal {
                    internal {
                        type
                    }
                    id
                    title
                    slug
                }
                ... on ContentfulAboutUs{
                    internal {
                        type
                    }
                    id
                    title
                    slug
                }
                ... on ContentfulFaq{
                  internal {
                      type
                  }
                  id
                  title
                  slug
                }
                ... on ContentfulContact{
                  internal {
                      type
                  }
                  id
                  title
                  slug
                }
            }
        }
    }
`
