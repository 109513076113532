import { DataStore } from './DataStore';

export class EncodedCookieDataStore extends DataStore {

    constructor ({ cookieName, encoderService, decoderService }) {
        super({ data: cookieName });
        this.encoderService = encoderService;
        this.decoderService = decoderService;
    }

    get appName () {
        return this.read('appName');
    }

    set appName (name) {
        this.write({ key: 'appName', data: name });
    }

    get token () {
        return this.read('token');
    }

    get utm_source () {
        return this.read('utm_source');
    }

    get utm_medium () {
        return this.read('utm_medium');
    }

    get utm_campaign () {
        return this.read('utm_campaign');
    }

    get applicationId () {
        return this.read('address_token');
    }

    get pdfId () {
        return this.read('pdfId');
    }

    set pdfId (name) {
        this.write({ key: 'pdfId', data: name });
    }


    set token (name) {
        this.write({ key: 'token', data: name });
    }

    set order (name) {
        this.write({ key: 'order', data: name });
    }

    set utm_source (name) {
        this.write({ key: 'utm_source', data: name });
    }

    set utm_medium (name) {
        this.write({ key: 'utm_medium', data: name });
    }

    set utm_campaign (name) {
        this.write({ key: 'utm_campaign', data: name });
    }

    get order () {
        return this.read('order');
    }

    set reference (name) {
        this.write({ key: 'reference', data: name });
    }

    get reference () {
        return this.read('reference');
    }

    read (key) {
        return this._getCookie()[key];
    }

    write ({ key, data }) {
        if(typeof document !== 'undefined'){
            const cookie = this._getCookie();
            cookie[key] = data;
            const decodedCookieValue = JSON.stringify(cookie);
            const encodedCookieValue = this.encoderService.encode(decodedCookieValue);
            document.cookie = `${this._data}=${encodedCookieValue};path=/`;
        }
    }

    _getCookie () {
        if(typeof document !== 'undefined'){
            const cookieName = this._data;
            const value = '; ' + document.cookie;
            const parts = value.split('; ' + cookieName + '=');
            if (parts.length === 2) {
                const encodedCookieValue = parts.pop().split(';').shift();
                const decodedCookieValue = this.decoderService.decode(encodedCookieValue);
                return JSON.parse(decodedCookieValue);
            }
        }
        return {};
    }

}


