import React from "react"
import CookiePolicy from "../CookiePolicy/cookiePolicy"
import Wrapper from "../Wrapper/wrapper"
import "./header.scss"
import { Link } from "gatsby"
import classNames from "classnames"

const Header = (props) => {
  let logoLink = <Link className="m-header-logo" to="/">
    <img src={`${process.env.LOGO_PATH}/logo.png`} alt="logo"/>
  </Link>

  const headerTitle = props.headerTitle ? <div><h2>{props.headerTitle}</h2></div> : null

  const classnames = classNames("m-header no-sticky", {
    "header-title": props.headerTitle,
  })

  return (
    <header className={classnames}>
      <CookiePolicy/>
      <Wrapper>
        <div className={headerTitle ? "m-header-title-container" : "m-header-container"}>
          {logoLink}
          {headerTitle}
        </div>
        {props.heahTitle}
      </Wrapper>
    </header>
  )
}

export default Header
