/* eslint-disable */
let scrollPos

const headerPosition = () => {
  if (typeof document !== "undefined") {
    if (document.body.getBoundingClientRect().top === 0) {
      document.body.classList.remove("scrollDown")
    } else {
      document.body.classList.add("scrollDown")
      scrollPos = document.body.getBoundingClientRect().top
    }
    document.addEventListener(
      "DOMContentLoaded",
      function() {
        scrollPos = 0
        document.body.classList.remove("scrollDown")
      },
      false
    )
  }
}

if (typeof window !== "undefined") {
  window.addEventListener("scroll", function() {
    headerPosition()
  })
}

export default headerPosition
