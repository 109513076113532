export default function contentAdder(content, siteData) {
  let cont = content;
  for (const key in siteData) {
    let replace = key;
    let re = new RegExp(replace,"g");
    cont = cont.replace(re, siteData[key]);
    // for(const text in cont){
    //   if(typeof cont[text] !== 'object'){
    //     cont[text] = cont[text].replace(re, data[key]);
    //   }
    // }
  }
  return cont
}