import React from "react"
import classNames from "classnames"
import "./wrapper.scss"

const Wrapper = props => {
  const classnames = classNames(`m-wrapper ${props.classNames}`, {
    // size
    "m-wrapper__collapse": props.collapse,
    "m-wrapper__flex": props.isFlex,
    "m-wrapper__payment": props.isPayment,
    "m-wrapper__small": props.small,
    "m-wrapper__faq": props.faq,
    "m-wrapper__sides": props.sides,
    "m-wrapper__contact": props.contact,
    "m-wrapper__contactSuccess": props.contactSuccess,
    "m-wrapper__medium": props.medium,
    "m-wrapper__steps": props.isSteps,
    "m-wrapper__page404": props.page404,
  })
  return (
    <>
      <div key={props.id} className={classnames}>{props.children}</div>
    </>
  )
}

export default Wrapper
