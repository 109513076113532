import React from "react"
import "./richText.scss"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import contentAdder from "../../../services/contentAdder/contentAdder"
import { graphql, StaticQuery } from "gatsby"
import { TableToggle } from "../TableToggle"
import { Title } from "../../Layout/Title/title"
import PhotoRequirements from "../../Modules/PhotoRequirements/photoRequirements.js"

const RichText = (props) => (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              company_name
              company_vat
              address_1
              address_2
              address_3
              web_price
              web_name
              web_full_name
              web_mail
              government_agency
              country_name
              disclaimer_payment1
              disclaimer_payment2
            }
          }
        }
    `}
      render={data => {
        const siteData = data.site.siteMetadata
        //const Bold = ({ children }) => <p className="bold">{children}</p>;
        const Text = ({ children }) => <p className=""> {children} </p>
        const Div = ({ children }) => <div className=""> {children} </div>
        const Heading4 = ({ children }) => <div className="e-title e-title__form"> {children} </div>

        const options = {
          renderMark: {
            [MARKS.BOLD]: text => {
              return <b key={`${text}-key`}>{text}</b>
            },
            [MARKS.CODE]: text => {
              let contPara = contentAdder(text, siteData)
              return <span dangerouslySetInnerHTML={{ __html: contPara }}/>
            },
          },
          renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: node => {

              return (
                <div className="e-image--content"><img alt={`${node.data.target.fields.title["en-US"]}`}
                                                       className="e-image"
                                                       src={`${node.data.target.fields.file["en-US"].url}`}/></div>
              )
            },
            [BLOCKS.PARAGRAPH]: (node, children) => {

              return (
                <Text>{children}</Text>
              )
            },
            [BLOCKS.HEADING_4]: (node, children) => {
              return (
                <Heading4>{children}</Heading4>
              )
            },
            [BLOCKS.UL_LIST]: (node, children) => {
              return (
                <ul className="e-list">{children}</ul>
              )
            },
            [BLOCKS.HEADING_6]: (node, children) => {
              return (
                <Title grand content={children}/>
              )
            },
            [BLOCKS.QUOTE]: (node, children) => {
              return (
                <div className="m-blockquote">
                  <PhotoRequirements/>
                </div>
              )
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
              if (node.data.target.fields) {
                if (node.data.target.fields.type) {
                  return (
                    <div dangerouslySetInnerHTML={{ __html: node.data.target.fields.table["en-US"] }}/>
                  )
                } else {
                  return (
                    <TableToggle
                      title={node.data.target.fields.title["en-US"]}
                      subtitle={node.data.target.fields.subtitle && node.data.target.fields.subtitle["en-US"]}
                      content={node.data.target.fields.table["en-US"]}/>
                  )
                }
              }
            },
          },
        }
        return (
          (
            <div className={props.customClass ? `m-richText ${props.customClass}` : "m-richText"}>
              {documentToReactComponents(props.content, options)}
            </div>
          )
        )
      }}
    />
  )



export default RichText

