import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import "./disclaimer.scss";
import RichText from "../../Modules/RichText/richText";

const Disclaimer = ({ data }) => (
     <div className="disclaimer"><RichText content={data.contentfulHome.disclaimer.json}></RichText></div>
)

export default props => (
    <StaticQuery
      query={graphql`
        query pageQuery {
          contentfulHome {
            disclaimer {
                json
            }
        }
        }
      `}
      render={data => (
        <Disclaimer data={data} {...props} />
      )}
    />
  )
      