import React, { Component } from "react"
import "./tableToggle.scss"
import Chevron from "./Chevron";

export class TableToggle extends Component {

    constructor() {
        super();
        this.state = {
            active: false
        };
        this.isOpen = this.isOpen.bind(this);
    }
    isOpen() {
        this.setState({
            active: !this.state.active,
        });
    }
    render() {
        const headerActiveClass = this.state.active ? "m-tableToggle__header m-tableToggle__open" : "m-tableToggle__header";
        const iconActiveClass = this.state.active ? "m-tableToggle--icon m-tableToggle--icon__open" : "m-tableToggle--icon";
        const bodyActiveClass = this.state.active ? "m-tableToggle__body m-tableToggle__body__open" : "m-tableToggle__body";

        return (
            <div className={this.props.subtitle ? "m-tableToggle" : "m-tableToggle m-tableToggle__light"}>
                <div className={headerActiveClass} onClick={this.isOpen}>
                    {this.props.title}
                    <Chevron className={iconActiveClass}  width={8} height={16} fill={"#383C56"} />
                </div>
                <div className={bodyActiveClass} >
                    {this.props.subtitle &&
                    <div className="m-tableToggle__subtitle">{this.props.subtitle}</div>
                    }
                    <div dangerouslySetInnerHTML={{ __html : this.props.content }} />
                </div>
            </div>
        );
    }
}



