import React from "react"
import classNames from "classnames"
import "./button.scss"
import Icon from "../../../images/svg/arrow_right_24px.svg"
import IconLocation from "../../../images/icons/location_outlined.svg"

export const Button = ({
                         dataQa,
                         regular,
                         label,
                         labelMobile,
                         link,
                         primary,
                         primaryAlt,
                         secondary,
                         secondaryAlt,
                         nolink,
                         withArrow,
                         withMarker,
                         disabled,
                         onClick,
                         functionParameters,
                         givenClass,
                         setTarget,
                       }) => {
  const classnames = classNames(`e-button ${givenClass}`, {
    "e-button-regular": regular,
    "e-button-primary-1": primary,
    "e-button-primary-2": primaryAlt,
    "e-button-secondary-1": secondary,
    "e-button-secondary-2": secondaryAlt,
    "e-button-disabled": disabled,

  })

  if (nolink === true) {
    return (
      <p
        className={`${classnames} `}
        data-qa={dataQa}
        data-anl="mainButton"
        onClick={() => onClick(functionParameters)}
      >
        {labelMobile ? (
          <>
            <label className="e-button-labelMobile">{labelMobile}</label>
            <label className="e-button-labelDesktop">{label}</label>
          </>
        ) : (
          <label>{label}</label>
        )}
      </p>
    )
  }
  return (
    <a
      href={link ? link : ""}
      className={classnames}
      data-qa={dataQa}
      data-anl="mainButton"
      target={setTarget ? setTarget : "_self"}
    >
        {withMarker && <img className="e-button-arrow" alt={label} src={IconLocation}/> }
      {labelMobile ? (
        <>
          <label className="e-button-labelMobile">{labelMobile}</label>
          <label className="e-button-labelDesktop">{label}</label>
        </>
      ) : (
        <label>{label}</label>
      )}
        {withArrow && <img className="e-button-arrow" alt="" src={Icon}/>}
    </a>
  )
}
